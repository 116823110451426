import React, { useState } from "react";
import "./Faq.css";
import Faq from "react-faq-component";

import { text } from "./text.js";

const FaqPage = () => {
  const [contact, setContact] = useState(false);

  const [status, setStatus] = useState("Submit");
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setStatus("Sending...");
      const { name, email, message } = e.target.elements;

      let details = {
        name: name.value,
        email: email.value,
        message: message.value,
      };
      console.log("details", details);
      await fetch("https://formspree.io/maywwwyy", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(details),
      });
      setStatus("Submit");
      // let result = await response.json();
      alert("Successfully sent the message!");
    } catch (err) {
      alert("Something went wrong. Please try again later!");
    }
  };
  return (
    <div className="faq">
      <div className="faq-header">
        <span>Frequently Asked Questions</span>
      </div>

      <Faq
        data={text}
        styles={{
          bgColor: "transparent",
          titleTextColor: "#fff",
          rowTitleColor: "#fff",
          rowTitleTextSize: "x-large",
          rowContentColor: "#fff",
          rowContentTextSize: "16px",
          rowContentPaddingTop: "10px",
          rowContentPaddingBottom: "10px",
          rowContentPaddingLeft: "10px",
          arrowColor: "red",
        }}
        config={{
          animate: true,
        }}
      />
      <div className="faq-bottom">
        <h4 className="faq-text">Question not on the list? Contact out help desk for further enquiries</h4>
        {!contact && (
          <button onClick={() => setContact(true)} className="btn btn-faq">
            Contact Us
          </button>
        )}
      </div>
      {contact && (
        <div className="form-section">
          <div className="contact-rules">
            <h3 style={{ fontWeight: "bold", fontSize: 18 }}>Important Reminders</h3>
            <span>Please wait 24-72 hours before sending another message</span>
            <span>This is another reminder</span>
            <span>This could be better explained</span>
            <span>What else could be part of this example</span>
          </div>
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="contact-section">
              <label className="contact-label" htmlFor="name">
                Name:
              </label>
              <input placeholder="Name" className="contact-input" type="text" id="name" required />
            </div>
            <div className="contact-section">
              <label className="contact-label" htmlFor="email">
                Email:
              </label>
              <input placeholder="Email" className="contact-input" type="email" id="email" required />
            </div>
            <div className="contact-section">
              <label className="contact-label" htmlFor="message">
                Message:
              </label>
              <textarea placeholder="Message" className="contact-text-input" id="message" required />
            </div>
            <button className="btn btn-submit" type="submit">
              {status}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default FaqPage;
