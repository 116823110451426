import React from "react";
import "./Hero.css";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";
import NumberCounter from "number-counter";
import Apple from "../../assets/applebtn.png";
import Google from "../../assets/googlebtn.png";
import Smartphone from "../Phone/Smartphone";
import { motion } from "framer-motion";
import { googleLink, appleLink } from "../../lib/env.js";

const Hero = () => {
  const transition = { type: "tween", duration: 2 };
  const transition2 = { type: "spring", duration: 3 };

  const mobile = window.innerWidth <= 768 ? true : false;

  return (
    <div className="hero" id="home">
      <div className="blur hero-blur"></div>
      <div className="left-h">
        <div className="the-best-ad">
          <motion.div initial={{ left: mobile ? "178px" : "238px" }} whileInView={{ left: "8px" }} transition={transition}></motion.div>
          <span>The first gamefied fitness app on the market</span>
        </div>
        <div className="hero-text">
          <div className="hero-main">
            <div>
              <span className="stroke-text">Gamified Fitness </span>
            </div>
            <span>Made Fun</span>
            <span style={{ display: "flex" }} className="stroke-text">
              and Easy
            </span>
          </div>

          <div style={{ fontSize: 16 }}>
            <span className="span">Create your ideal body by challenging yourself with our workouts</span>
          </div>
        </div>
        <div className="figures">
          <div>
            <NumberCounter end={250} start={100} delay="4" preFix="+" />
            <span>Exercises</span>
          </div>
          <div>
            <NumberCounter end={100} start={10} delay="4" preFix="+" />
            <span>Workouts</span>
          </div>

          <div>
            <NumberCounter end={10} start={1} delay="4" preFix="+" />
            <span>training plans</span>
          </div>
        </div>
        <div className="link-text">
          <span>Download the app on your phone today</span>
        </div>
        <div className="store-btn">
          <a href={appleLink} rel="noopener noreferrer" target="_blank">
            <img src={Apple} alt="apple store" className="link-btn" />
          </a>
          <a href={googleLink} rel="noopener noreferrer" target="_blank">
            <img src={Google} alt="google store" className="link-btn" />
          </a>
        </div>
      </div>
      <div className="right-h">
        <motion.div initial={{ right: "-1rem" }} whileInView={{ right: "1.5rem" }} transition={transition2} className="heart-rate">
          <motion.img
            transition={{ repeat: Infinity, duration: 2 }}
            animate={{
              scale: [1, 0.5, 1, 0.5, 1],
            }}
            src={Heart}
            alt=""
          />

          <span>Hearth Rate</span>
          <span>116 bpm</span>
        </motion.div>
        <div className="hero-image">
          <Smartphone />
        </div>
        {/* <img src={hero_image} alt="" className="hero-image" /> */}
        <motion.img initial={{ right: "11rem" }} transition={transition2} whileInView={{ right: "20rem" }} src={hero_image_back} alt="" className="hero-image-back" />
        <motion.div initial={{ right: "7rem" }} whileInView={{ right: "1.5rem" }} transition={transition2} className="calories">
          <img src={Calories} alt="" />
          <div>
            <span>Calories Burnt</span>
            <span>220 kcal</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
