import React from "react";

import { Privacy } from "./text";

export default function PrivacyInfo() {
  return (
    <div className="terms">
      <span className="h1">{Privacy.text1}</span>
      <div className="m-container">
        <p className="p-main">{Privacy.text2}</p>
        <ul>
          <li className="p-main">{Privacy.text3}</li>
          <li className="p-main">{Privacy.text4}</li>
        </ul>

        <p className="p-main2">{Privacy.text5}</p>
        <p className="p-main">{Privacy.text6}</p>
        <ul>
          <li className="p-main">{Privacy.text7}</li>
          <li className="p-main">{Privacy.text8}</li>
          <li className="p-main">{Privacy.text9}</li>
          <li className="p-main">{Privacy.text10}</li>
        </ul>

        <p className="p-main2">{Privacy.text11}</p>

        <p className="p-main">{Privacy.text12}</p>
        <p className="p-main2">{Privacy.text13}</p>

        <p className="p-main">{Privacy.text14}</p>
        <ul>
          <li className="p-main">{Privacy.text15}</li>
          <li className="p-main">{Privacy.text16}</li>
          <li className="p-main">{Privacy.text17}</li>
          <li className="p-main">{Privacy.text18}</li>
        </ul>

        <p className="p-main2">{Privacy.text19}</p>
        <p className="p-main">{Privacy.text20}</p>
        <p className="p-main2">{Privacy.text21}</p>
        <p className="p-main">{Privacy.text22}</p>
        <p className="p-main">{Privacy.text23}</p>
        <p className="p-main">{Privacy.text24}</p>
        <p className="p-main">{Privacy.text25}</p>
      </div>
    </div>
  );
}
