import React, { useState, useEffect } from "react";
import Smartphone1 from "../../assets/smartphone.png";
import { motion } from "framer-motion";
import focus from "../../assets/screenshots/focus.png";
import goals from "../../assets/screenshots/goals.png";
import leadershipBoard from "../../assets/screenshots/leadershipboard.png";
import leadershipScore from "../../assets/screenshots/leadershipScore.png";
import level from "../../assets/screenshots/level.png";
import workout from "../../assets/screenshots/workout.png";
import workout1 from "../../assets/screenshots/workout1.png";

const images = [
  {
    original: focus,
    thumbnail: focus,
  },
  {
    original: goals,
    thumbnail: goals,
  },
  {
    original: leadershipBoard,
    thumbnail: leadershipBoard,
  },
  {
    original: leadershipScore,
    thumbnail: leadershipScore,
  },
  {
    original: level,
    thumbnail: level,
  },
  {
    original: workout,
    thumbnail: workout,
  },
  {
    original: workout1,
    thumbnail: workout1,
  },
];

const Smartphone = () => {
  const [selected, setSelected] = useState(0);
  const [direction, setDirection] = useState(null);
  const [pressed, setPressed] = useState(null);
  const transition = { type: "tween", duration: 0.2 };
  const tLength = images.length;

  useEffect(() => {
    if (!pressed) {
      setTimeout(() => {
        selected >= tLength - 1 ? setSelected(0) : setSelected((prev) => prev + 1);
        setDirection("next");
      }, 3000);
    }
  }, [selected, pressed, tLength]);
  return (
    <div className="phone-container">
      <img src={Smartphone1} alt="" className="smartphone" />
      <div className="display-img">
        <motion.img key={selected} alt="" initial={{ opacity: 0, x: direction === "next" ? 100 : -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: direction !== "next" ? 100 : -100 }} transition={transition} src={images[selected]?.original} className="slide-img" />;
      </div>
      <div className="slide-btn">
        <button
          className="btn"
          onClick={() => {
            setDirection("prev");
            !pressed && setSelected(0);
            setPressed(true);
            pressed && (selected === 0 ? setSelected(tLength - 1) : setSelected((prev) => prev - 1));
          }}
        >
          {"<"}
        </button>
        <button
          className="btn"
          onClick={() => {
            setDirection("next");
            !pressed && setSelected(0) && setDirection("next");
            setPressed(true);
            selected === tLength - 1 ? setSelected(0) : setSelected((prev) => prev + 1);
          }}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

export default Smartphone;
