import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Faq from "./pages/Faq";
import Terms from "./pages/Terms";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import {
  BrowserRouter as Router,
  Routes, //replaces "Switch" used till v5
  Route,
} from "react-router-dom";

function App() {
  return (
    <div className="App1">
      <Router>
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/faq" element={<Faq />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
