import React from "react";
import "./Terms.css";
import { Terms } from "./text";

export default function TermsInfo() {
  return (
    <div className="terms">
      <span className="h1">Terms & Conditions</span>
      <div className="m-container">
        <p className="p-main2">1. {Terms.text1}</p>
        <p className="p-main">{Terms.text2}</p>
        <p className="p-main">A. {Terms.text3}</p>
        <p className="p-main">B. {Terms.text4}</p>
        <p className="p-main">C. Further, you agree to indemnify us and hold us harmless from any liability associated with your use of any of our apps and to immediately stop using our apps upon any notice that such use may be considered an infringing act. In no event will our liability to you exceed the amount you have paid for our apps and any In App Purchases made within our apps.</p>
        <p className="p-main2">2. {Terms.text5}</p>
        <p className="p-main">{Terms.text0}</p>
        <p className="p-main">{Terms.text6}</p>

        <p className="p-main2">3. {Terms.text7}</p>
        <p className="p-main">{Terms.text8}</p>

        <p className="p-main2">4. {Terms.text9}</p>
        <p className="p-main">{Terms.text10}</p>

        <p className="p-main2">5. {Terms.text11}</p>
        <p className="p-main">a. {Terms.text12}</p>
        <p className="p-main">b. {Terms.text13}</p>
        <p className="p-main">c. {Terms.text14}</p>
        <p className="p-main">d. {Terms.text15}</p>

        <p className="p-main2">6. {Terms.text16}</p>
        <p className="p-main">{Terms.text17}</p>
        <ul>
          <li className="p-main">{Terms.text18}</li>
          <li className="p-main">{Terms.text19}</li>
          <li className="p-main">{Terms.text20}</li>
          <li className="p-main">{Terms.text21}</li>
          <li className="p-main">{Terms.text22}</li>
          <li className="p-main">{Terms.text23}</li>
          <li className="p-main">{Terms.text24}</li>
          <li className="p-main">{Terms.text25}</li>
          <li className="p-main">{Terms.text26}</li>
          <li className="p-main">{Terms.text27}</li>
          <li className="p-main">{Terms.text28}</li>
          <li className="p-main">{Terms.text29}</li>
          <li className="p-main">{Terms.text30}</li>
          <li className="p-main">{Terms.text31}</li>
          <li className="p-main">{Terms.text32}</li>
        </ul>
        <p className="p-main">{Terms.text33}</p>
        <p className="p-main">{Terms.text34}</p>

        <p className="p-main2">7. {Terms.text35}</p>
        <p className="p-main">{Terms.text36}</p>

        <p className="p-main2">8. {Terms.text37}</p>
        <p className="p-main">{Terms.text38}</p>
        <p className="p-main">{Terms.text39}</p>
        <p className="p-main">{Terms.text40}</p>
        <p className="p-main">{Terms.text41}</p>

        <p className="p-main2">9. {Terms.text42}</p>
        <p className="p-main">{Terms.text43}</p>
        <p className="p-main">{Terms.text44}</p>
        <p className="p-main">{Terms.text45}</p>

        <p className="p-main2">10. {Terms.text46}</p>
        <p className="p-main">{Terms.text47}</p>

        <p className="p-main2">11. {Terms.text48}</p>
        <p className="p-main">{Terms.text49}</p>
        <p className="p-main">{Terms.text50}</p>

        <p className="p-main2">12. {Terms.text51}</p>
        <p className="p-main">a. {Terms.text52}</p>
        <p className="p-main">b. {Terms.text53}</p>
        <p className="p-main">c. {Terms.text54}</p>
        <p className="p-main">d. {Terms.text55}</p>
        <p className="p-main">e. {Terms.text56}</p>
        <p className="p-main">f. {Terms.text57}</p>

        <p className="p-main2">13. {Terms.text58}</p>
        <p className="p-main">{Terms.text59}</p>
        <p className="p-main">{Terms.text60}</p>
        <p className="p-main">{Terms.text61}</p>

        <p className="p-main2">14. {Terms.text62}</p>
        <p className="p-main">{Terms.text63}</p>

        <p className="p-main2">15. {Terms.text64}</p>
        <p className="p-main">{Terms.text65}</p>

        <p className="p-main2">16. {Terms.text66}</p>
        <p className="p-main">{Terms.text67}</p>

        <p className="p-main2">17. {Terms.text68}</p>
        <p className="p-main">{Terms.text69}</p>

        <p className="p-main2">18. {Terms.text70}</p>
        <p className="p-main">{Terms.text71}</p>

        <p className="p-main2">19. {Terms.text72}</p>
        <p className="p-main">{Terms.text73}</p>

        <p className="p-main2">20. {Terms.text74}</p>
        <p className="p-main">{Terms.text75}</p>
        <p className="p-main">{Terms.text76}</p>

        <p className="p-main2">21. {Terms.text77}</p>
        <p className="p-main">{Terms.text78}</p>
        <p className="p-main">{Terms.text79}</p>
        <p className="p-main2">22. Subscription Policy</p>
        <ul>
          <li className="p-main">BodyweightPro uses Auto-renewable subscriptions which are available from the App Store and Google Play Store.</li>
          <li className="p-main">Payment will be charged to your iTunes account or google play store account at confirmation of purchase and will automatically renew (at the duration/price selected) unless auto-renew is turned off at least 24 hrs before the end of the current period.</li>
          <li className="p-main">Your Account will be charged for renewal within 24-hours prior to the end of the current period.</li>
          <li className="p-main">Current subscription may not be cancelled during the active subscription period; however, you can manage your subscription and/or turn off auto-renewal by visiting your iTunes Account Settings or Google Play store account settings after purchase.</li>
          <li className="p-main">Any unused portion of the free one week initial period will be forfeited when you purchase a subscription.</li>
        </ul>
      </div>
    </div>
  );
}
