import game from "../assets/app/total.png";
import timer from "../assets/app/stopwatch.png";
import endurance from "../assets/app/endurance.png";
import level from "../assets/app/power.png";

export const programsData = [
  {
    image: game,
    heading: "Gamified Fitness Experience",
    details: "Collect badges and level up in a variety of areas. Collect points based on your preferences and weaknesses.",
  },
  {
    image: timer,
    heading: "Customize the Timer",
    details: "Change the timer and add circuits to create a unique and challenging workout experience.",
  },
  {
    image: endurance,
    heading: "Plenty of Workout Option",
    details: "The workouts are broken down into strength, flexibility and cardio allowing you to freely choose what to focus on.",
  },
  {
    image: level,
    heading: "Workouts for Everyone",
    details: "Choose between different intensities to challenge yourself.",
  },
];
