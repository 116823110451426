import React from "react";
import Hero from "../components/Hero/Hero";
import Programs from "../components/Programs/Programs";
import Reasons from "../components/Reasons/Reasons";
// import Plans from "../components/Plans/Plans";
// import Testimonials from "../components/Testimonials/Testimonials";
// import Join from "../components/Join/Join";
import Phone from "../components/Phone/Phone";

const Home = () => {
  return (
    <div>
      <Hero />
      <Programs />
      <Phone />
      <Reasons />
      {/* <Plans /> */}
      {/* <Testimonials /> */}
      {/* <Join /> */}
    </div>
  );
};

export default Home;
