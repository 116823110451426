import "./Header.css";
import { useState, useEffect, useRef } from "react";
import Logo from "../../assets/app/splash.png";
import Bars from "../../assets/bars.png";
import React from "react";
import { scroller } from "react-scroll";
import { useNavigate } from "react-router-dom";

const Header = () => {
  let navigate = useNavigate();
  const menuRef = useRef(null);
  const scrollTarget = (target) => scroller.scrollTo(target, { smooth: true, duration: 700 });
  const [menuOpened, setMenuOpened] = useState(false);
  const [listening, setListening] = useState(false);
  const toggle = () => setMenuOpened(!menuOpened);

  const [width, setWidth] = React.useState(window.innerWidth);
  const mobile = width <= 768 ? true : false;

  const handleResize = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  const listenForOutsideClicks = (listening, setListening, menuRef, setIsOpen) => {
    return () => {
      if (listening) return;
      if (!menuRef.current) return;
      setListening(true);
      [`click`, `touchstart`].forEach((type) => {
        document.addEventListener(`click`, (evt) => {
          if (menuRef.current.contains(evt.target)) return;
          setIsOpen(false);
        });
      });
    };
  };

  useEffect(() => {
    listenForOutsideClicks(listening, setListening, menuRef, setMenuOpened);
  }, [listening]);

  const scrollToPage = async (target) => {
    if (window.location.pathname !== "/") {
      await navigate("/");
    }
    scrollTarget(target);
    setMenuOpened(false);
  };

  return (
    <div className="header" ref={menuRef}>
      <img src={Logo} className="logo" alt="" />
      {menuOpened === false && mobile === true ? (
        <div onClick={() => toggle()} style={{ backgroundColor: "var(--appColor)", padding: "0.5rem ", borderRadius: "5px" }}>
          <img src={Bars} alt="" style={{ width: "1.5rem", height: "1rem", marginTop: "4px" }} />
        </div>
      ) : (
        <div>
          <ul className="header-menu">
            <li onClick={() => scrollToPage("home")}>Home</li>
            <li onClick={() => scrollToPage("programs")}>Programs</li>
            <li onClick={() => scrollToPage("phone")}>The App</li>
            <li onClick={() => scrollToPage("reasons")}>Why Us</li>
            {/* <li onClick={() => scrollToPage("plans")}>Plans</li> */}
            {/* <li onClick={() => scrollToPage("testimonials")}>Testimonials</li> */}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Header;
