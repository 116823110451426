export const text = {
  rows: [
    {
      title: "How do i become a premium member?",
      content: "Inside the app there is an option to sign up for the premium membership. Follow the links until you get to premium options.",
    },
    {
      title: "Is there a trail period?",
      content: "Yes, there is a 7 day trial period for everyone signing up for the first time. You can cancel at any time during that period to receive a full refund?",
    },
    {
      title: "What makes this app gamified?",
      content: "There are many apps that give out badges, which only scratches the surfaces of what makes an app gamified. BodyweightPro adds levels, points and trophies to enhance the game-like experience. You also have the option to partake in the leadership board by sharing your results with the rest of the app users.",
    },
    {
      title: "Can I invite friends to my leadership board?",
      content: "Yes, you can invite users to your leadership board to compete against friends and family and keep track of their performance.",
    },
    {
      title: "What happens if I can't hear the coaches voice?",
      content: "If you experience any problems that appear unusual, please get back to us and we'll try our best to troubleshoot the issue as soon as possible.",
    },
  ],
};

// "InformationScreen": {
//   "title1": "How do tailor the calorie count?",
//   "content1": "You will find an option to adjust the weight and height in the settings menu. After adjusting the personal details each exercise will be adjusted to calculate your person caloric burn rate.",
//   "title2": "How can reset my stats?",
//   "content2": "A reset button can be found at the bottom of the settings page.",
//   "title3": "Are the workouts safe for children and elderly people?",
//   "content3": "The beginner workouts are relatively safe for all age groups. Start slowly when you are attempting the medium or hard workouts. If you have any doubts, please visit your nearest physician to test your current physical health.",
//   "title4": "Will the workouts really help me lose weight?",
//   "content4": "Every workout is designed to help you lose weight. Some workouts will help you lose more weight than other workouts depending on the overall goal and the intensity.",
//   "title5": "How do I reset the notifications?",
//   "content5": "Turn the notifications off in the settings menu to deactivate all notifications. Afterward you can turn the notifications on again to select a new time.",
//   "title6": "Does the premium version come with a guarantee?",
//   "content6": "Yes.The premium version comes with a 7 day guarantee. If you are not pleased with the purchase you will receive a full refund by clicking on the button found at the bottom of the premium screen.",
//   "title7": "Will I be able to purchase the life-time membership offer next month?",
//   "content7": "The life-time membership deal is only available for a limited time. The developers can remove the life-time membership and add a monthly subscription package at any time.",
//   "faq": "Frequently Asked Questions"
// },
