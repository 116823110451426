import React from "react";
import "./Reasons.css";
import tick from "../../assets/tick.png";
import Afternoon from "../../assets/app/Afternoon.png";
import Calories from "../../assets/app/Calories.png";
import Cardio from "../../assets/app/Cardio.png";
import Exercises from "../../assets/app/Exercises.png";
import Flexibility from "../../assets/app/Flexibility.png";
import Morning from "../../assets/app/Morning.png";
import Night from "../../assets/app/Night.png";
import Strength from "../../assets/app/Strength.png";
import Stroke from "../../assets/app/Stroke.png";
import Time from "../../assets/app/Time.png";
import leadershipBoard from "../../assets/screenshots/leadershipboard.png";

const Reasons = () => {
  const allIcons = [Afternoon, Calories, Cardio, Exercises, Flexibility, Morning, Night, Strength, Stroke, Time];
  return (
    <div className="Reasons" id="reasons">
      <div className="right-r">
        <span>Next Level Fitness Experience</span>
        <div>
          <span className="stroke-text">What is Gamified</span>
          <span> Fitness?</span>
        </div>
        <div className="details-r">
          <div style={{ flexWrap: "wrap", marginBottom: 10 }}>
            {allIcons.map((a, i) => (
              <img key={i} src={a} alt="icons" style={{ height: 30, width: 35 }} />
            ))}
          </div>
          <div>
            <img src={tick} alt=""></img>
            <span>Level up in a variety of areas</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>Gain more insight into your performance</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>Use a game like fitness path to enhance motivation</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>Challenge other users and invite friends and family</span>
          </div>
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        <img style={{ maxWidth: 300, height: 400, borderRadius: 10 }} src={leadershipBoard} alt="leadership board" />
      </div>
    </div>
  );
};

export default Reasons;
