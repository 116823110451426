import React from "react";
import "./Footer.css";
// import Github from "../../assets/github.png";
// import Instagram from "../../assets/instagram.png";
// import LinkedIn from "../../assets/linkedin.png";
import Logo from "../../assets/app/splash.png";
import { Link } from "react-router-dom";
import Apple from "../../assets/applebtn.png";
import Google from "../../assets/googlebtn.png";
import { appleLink, googleLink } from "../../lib/env.js";

const Footer = () => {
  return (
    <div className="Footer-container">
      <div className="footer">
        <div className="web-links">
          <Link to="faq" onClick={() => window.scrollTo(0, 0)}>
            FAQ
          </Link>
          <Link to="terms" onClick={() => window.scrollTo(0, 0)}>
            Terms
          </Link>
          <Link to="privacy" onClick={() => window.scrollTo(0, 0)}>
            Privacy
          </Link>
          {/* <div className="social-links">
            <img src={Github} alt="" />
            <img src={Instagram} alt="" />
            <img src={LinkedIn} alt="" />
          </div> */}
        </div>
        <div className="store-btn" style={{ marginTop: 0 }} id="store-footer">
          <a href={appleLink} rel="noopener noreferrer" target="_blank">
            <img src={Apple} alt="" className="link-btn" />
          </a>
          <a href={googleLink} rel="noopener noreferrer" target="_blank">
            <img src={Google} alt="" className="link-btn" />
          </a>
        </div>

        <div className="logo-f">
          <img src={Logo} alt="" />
        </div>
      </div>
      <div className="blur blur-f-1"></div>
      <div className="blur blur-f-2"></div>
    </div>
  );
};

export default Footer;
