import React from "react";
import "./Programs.css";
import { programsData } from "../../data/programsData.js";

const Programs = () => {
  return (
    <div className="Programs" id="programs">
      <div className="programs-header">
        <span className="stroke-text">Bodyweight Fitness</span>
        <span>Programs</span>
        <span className="stroke-text">and beyond</span>
      </div>

      <div className="program-categories">
        {programsData.map((program, i) => (
          <div className="category" key={i}>
            <img style={{ height: 60, width: 60 }} alt={program.heading} src={program.image} />
            <span>{program.heading}</span>
            <span>{program.details}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Programs;
