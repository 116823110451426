import React from "react";
import "./Phone.css";
import tick from "../../assets/tick.png";
import WorkoutActive from "../../assets/screenshots/workout.png";

const importantPoints = ["Watch real people perform the exercises and get tips on alignment, positioning and ways to modify the exercises.", "Get real voice assistance on how to perform the exercise with important pointers.", "Choose to collect stars during your workout to stay motivated and engaged.", "Compete against other people and invite friends and family to keep track of everyone's performance."];

const Phone = () => {
  return (
    <div className="Phone" id="phone">
      <div className="p-right">
        <div className="r-title">
          <span className="title">Train With</span>
          <span className="stroke-text"> real people</span>
        </div>
        <div className="main-list">
          {importantPoints.map((a, i) => (
            <div key={i} className="list-container">
              <img src={tick} alt=""></img>
              <span className="point-list">{a}</span>
            </div>
          ))}
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        <img style={{ maxWidth: 300, height: 400, borderRadius: 10 }} src={WorkoutActive} alt="" />
      </div>
    </div>
  );
};

export default Phone;
